@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI','Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.doughnut-0 {
  width: 2.5rem;
  height: 2.5rem; 
  background-color: #f472b6;
  border-radius: 9999px; 
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 50%);
  right: 0;
  top: 0;
}

.doughnut-1 {
  width: 2.5rem; 
  height: 2.5rem;
  background-color: #8D2CFF; 
  border-radius: 9999px; 
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  right: 0;
  top: 0;
}

.doughnut-2 {
  width: 2.5rem; 
  height: 2.5rem;
  background-color: #28CDCF; 
  border-radius: 9999px; 
  right: 0;
  top: 0;
}

.doughnut-3 {
  width: 2.5rem; 
  height: 2.5rem;
  background-color: #2A2298; 
  border-radius: 9999px; 
  right: 0;
  top: 0;
}
.step.active .step-number {
  background-color: white;
  color: #2E3C43;
}
.step.done .step-number {
  background-color: #397BFF;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
}
.step.done:not(:last-child):after {
  border-left: 1px solid #3490dc;
}
.step:not(:last-child):after {
  content: "";
  position: absolute;
  left: 19px;
  top: 30px;
  bottom: -70px;
  border-left: 1px solid white;
}
.next-step.active {
  color: #3490dc;
}
.next-step:not(:last-child)::after {
  content: '>';
  padding: 0px 8px;
}

.shadow-top{
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}

.crm-button:hover{
  background-color: #3490dc;
}
.crm-button:hover span{
  color: #fff;
}
.is-disabled{
  filter: grayscale(100%);
}

.PhoneInputInput{

  padding: 0.4375rem 0.4375rem;

  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 3px;
}

.PhoneInputInput:enabled:hover {
  border-color: #2196f3;
}

.PhoneInputInput:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
}

.PhoneInputInput:disabled{
  opacity: 0.6;
}

.invalid .PhoneInputInput{
  border-color: #f44336;
}

.invalid .PhoneInputInput:enabled:hover {
  border-color: #f44336;
}

.invalid .PhoneInputInput:enabled:focus {
  border-color: #f44336;
}

[data-pc-name="iconfield"] {
  width: 100%;
}

.p-input-icon{
  display: flex;
}

.truncate-message{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  /* max-height: 2.4em; */
}
.important-bg-white {
  background-color: rgba(0, 0, 0, 0) !important;
}

@keyframes blink-border {
  0% {
    border-color: purple;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: purple;
  }
}

.expand-row-icon-custom {
  border-color: purple;
  animation: blink-border 8s infinite;
}
/* img, video {
  max-width: none !important;
} */

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 99999 !important;
}

.p-toast {
  z-index: 999 !important; 
}