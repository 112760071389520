
.country{

  display: flex;
  align-items: center;
  padding: 8px 8px;
  outline: none;
}
.flag-box {
  display: inline-block;
  width: 20px;
}

.flag{
  width: 20px;
}

.flag.flag-be {
  width: 18px;
}
.flag.flag-ch {
  width: 15px;
}
.flag.flag-mc {
  width: 19px;
}
.flag.flag-ne {
  width: 18px;
}
.flag.flag-np {
  width: 13px;
}
.flag.flag-va {
  width: 15px;
}
@media (min-resolution: 2x) {
  .flag {
    background-size: 5762px 15px;
  }
}
.flag.flag-ac {
  height: 10px;
  background-position: 0px 0px;
}
.flag.flag-ad {
  height: 14px;
  background-position: -22px 0px;
}
.flag.flag-ae {
  height: 10px;
  background-position: -44px 0px;
}
.flag.flag-af {
  height: 14px;
  background-position: -66px 0px;
}
.flag.flag-ag {
  height: 14px;
  background-position: -88px 0px;
}
.flag.flag-ai {
  height: 10px;
  background-position: -110px 0px;
}
.flag.flag-al {
  height: 15px;
  background-position: -132px 0px;
}
.flag.flag-am {
  height: 10px;
  background-position: -154px 0px;
}
.flag.flag-ao {
  height: 14px;
  background-position: -176px 0px;
}
.flag.flag-aq {
  height: 14px;
  background-position: -198px 0px;
}
.flag.flag-ar {
  height: 13px;
  background-position: -220px 0px;
}
.flag.flag-as {
  height: 10px;
  background-position: -242px 0px;
}
.flag.flag-at {
  height: 14px;
  background-position: -264px 0px;
}
.flag.flag-au {
  height: 10px;
  background-position: -286px 0px;
}
.flag.flag-aw {
  height: 14px;
  background-position: -308px 0px;
}
.flag.flag-ax {
  height: 13px;
  background-position: -330px 0px;
}
.flag.flag-az {
  height: 10px;
  background-position: -352px 0px;
}
.flag.flag-ba {
  height: 10px;
  background-position: -374px 0px;
}
.flag.flag-bb {
  height: 14px;
  background-position: -396px 0px;
}
.flag.flag-bd {
  height: 12px;
  background-position: -418px 0px;
}
.flag.flag-be {
  height: 15px;
  background-position: -440px 0px;
}
.flag.flag-bf {
  height: 14px;
  background-position: -460px 0px;
}
.flag.flag-bg {
  height: 12px;
  background-position: -482px 0px;
}
.flag.flag-bh {
  height: 12px;
  background-position: -504px 0px;
}
.flag.flag-bi {
  height: 12px;
  background-position: -526px 0px;
}
.flag.flag-bj {
  height: 14px;
  background-position: -548px 0px;
}
.flag.flag-bl {
  height: 14px;
  background-position: -570px 0px;
}
.flag.flag-bm {
  height: 10px;
  background-position: -592px 0px;
}
.flag.flag-bn {
  height: 10px;
  background-position: -614px 0px;
}
.flag.flag-bo {
  height: 14px;
  background-position: -636px 0px;
}
.flag.flag-bq {
  height: 14px;
  background-position: -658px 0px;
}
.flag.flag-br {
  height: 14px;
  background-position: -680px 0px;
}
.flag.flag-bs {
  height: 10px;
  background-position: -702px 0px;
}
.flag.flag-bt {
  height: 14px;
  background-position: -724px 0px;
}
.flag.flag-bv {
  height: 15px;
  background-position: -746px 0px;
}
.flag.flag-bw {
  height: 14px;
  background-position: -768px 0px;
}
.flag.flag-by {
  height: 10px;
  background-position: -790px 0px;
}
.flag.flag-bz {
  height: 12px;
  background-position: -812px 0px;
}
.flag.flag-ca {
  height: 10px;
  background-position: -834px 0px;
}
.flag.flag-cc {
  height: 10px;
  background-position: -856px 0px;
}
.flag.flag-cd {
  height: 15px;
  background-position: -878px 0px;
}
.flag.flag-cf {
  height: 14px;
  background-position: -900px 0px;
}
.flag.flag-cg {
  height: 14px;
  background-position: -922px 0px;
}
.flag.flag-ch {
  height: 15px;
  background-position: -944px 0px;
}
.flag.flag-ci {
  height: 14px;
  background-position: -961px 0px;
}
.flag.flag-ck {
  height: 10px;
  background-position: -983px 0px;
}
.flag.flag-cl {
  height: 14px;
  background-position: -1005px 0px;
}
.flag.flag-cm {
  height: 14px;
  background-position: -1027px 0px;
}
.flag.flag-cn {
  height: 14px;
  background-position: -1049px 0px;
}
.flag.flag-co {
  height: 14px;
  background-position: -1071px 0px;
}
.flag.flag-cp {
  height: 14px;
  background-position: -1093px 0px;
}
.flag.flag-cq {
  height: 12px;
  background-position: -1115px 0px;
}
.flag.flag-cr {
  height: 12px;
  background-position: -1137px 0px;
}
.flag.flag-cu {
  height: 10px;
  background-position: -1159px 0px;
}
.flag.flag-cv {
  height: 12px;
  background-position: -1181px 0px;
}
.flag.flag-cw {
  height: 14px;
  background-position: -1203px 0px;
}
.flag.flag-cx {
  height: 10px;
  background-position: -1225px 0px;
}
.flag.flag-cy {
  height: 14px;
  background-position: -1247px 0px;
}
.flag.flag-cz {
  height: 14px;
  background-position: -1269px 0px;
}
.flag.flag-de {
  height: 12px;
  background-position: -1291px 0px;
}
.flag.flag-dg {
  height: 10px;
  background-position: -1313px 0px;
}
.flag.flag-dj {
  height: 14px;
  background-position: -1335px 0px;
}
.flag.flag-dk {
  height: 15px;
  background-position: -1357px 0px;
}
.flag.flag-dm {
  height: 10px;
  background-position: -1379px 0px;
}
.flag.flag-do {
  height: 14px;
  background-position: -1401px 0px;
}
.flag.flag-dz {
  height: 14px;
  background-position: -1423px 0px;
}
.flag.flag-ea {
  height: 14px;
  background-position: -1445px 0px;
}
.flag.flag-ec {
  height: 14px;
  background-position: -1467px 0px;
}
.flag.flag-ee {
  height: 13px;
  background-position: -1489px 0px;
}
.flag.flag-eg {
  height: 14px;
  background-position: -1511px 0px;
}
.flag.flag-eh {
  height: 10px;
  background-position: -1533px 0px;
}
.flag.flag-er {
  height: 10px;
  background-position: -1555px 0px;
}
.flag.flag-es {
  height: 14px;
  background-position: -1577px 0px;
}
.flag.flag-et {
  height: 10px;
  background-position: -1599px 0px;
}
.flag.flag-eu {
  height: 14px;
  background-position: -1621px 0px;
}
.flag.flag-ez {
  height: 14px;
  background-position: -1643px 0px;
}
.flag.flag-fi {
  height: 12px;
  background-position: -1665px 0px;
}
.flag.flag-fj {
  height: 10px;
  background-position: -1687px 0px;
}
.flag.flag-fk {
  height: 10px;
  background-position: -1709px 0px;
}
.flag.flag-fm {
  height: 11px;
  background-position: -1731px 0px;
}
.flag.flag-fo {
  height: 15px;
  background-position: -1753px 0px;
}
.flag.flag-fr {
  height: 14px;
  background-position: -1775px 0px;
}
.flag.flag-fx {
  height: 14px;
  background-position: -1797px 0px;
}
.flag.flag-ga {
  height: 15px;
  background-position: -1819px 0px;
}
.flag.flag-gb {
  height: 10px;
  background-position: -1841px 0px;
}
.flag.flag-gd {
  height: 12px;
  background-position: -1863px 0px;
}
.flag.flag-ge {
  height: 14px;
  background-position: -1885px 0px;
}
.flag.flag-gf {
  height: 14px;
  background-position: -1907px 0px;
}
.flag.flag-gg {
  height: 14px;
  background-position: -1929px 0px;
}
.flag.flag-gh {
  height: 14px;
  background-position: -1951px 0px;
}
.flag.flag-gi {
  height: 10px;
  background-position: -1973px 0px;
}
.flag.flag-gl {
  height: 14px;
  background-position: -1995px 0px;
}
.flag.flag-gm {
  height: 14px;
  background-position: -2017px 0px;
}
.flag.flag-gn {
  height: 14px;
  background-position: -2039px 0px;
}
.flag.flag-gp {
  height: 14px;
  background-position: -2061px 0px;
}
.flag.flag-gq {
  height: 14px;
  background-position: -2083px 0px;
}
.flag.flag-gr {
  height: 14px;
  background-position: -2105px 0px;
}
.flag.flag-gs {
  height: 10px;
  background-position: -2127px 0px;
}
.flag.flag-gt {
  height: 13px;
  background-position: -2149px 0px;
}
.flag.flag-gu {
  height: 11px;
  background-position: -2171px 0px;
}
.flag.flag-gw {
  height: 10px;
  background-position: -2193px 0px;
}
.flag.flag-gy {
  height: 12px;
  background-position: -2215px 0px;
}
.flag.flag-hk {
  height: 14px;
  background-position: -2237px 0px;
}
.flag.flag-hm {
  height: 10px;
  background-position: -2259px 0px;
}
.flag.flag-hn {
  height: 10px;
  background-position: -2281px 0px;
}
.flag.flag-hr {
  height: 10px;
  background-position: -2303px 0px;
}
.flag.flag-ht {
  height: 12px;
  background-position: -2325px 0px;
}
.flag.flag-hu {
  height: 10px;
  background-position: -2347px 0px;
}
.flag.flag-ic {
  height: 14px;
  background-position: -2369px 0px;
}
.flag.flag-id {
  height: 14px;
  background-position: -2391px 0px;
}
.flag.flag-ie {
  height: 10px;
  background-position: -2413px 0px;
}
.flag.flag-il {
  height: 15px;
  background-position: -2435px 0px;
}
.flag.flag-im {
  height: 10px;
  background-position: -2457px 0px;
}
.flag.flag-in {
  height: 14px;
  background-position: -2479px 0px;
}
.flag.flag-io {
  height: 10px;
  background-position: -2501px 0px;
}
.flag.flag-iq {
  height: 14px;
  background-position: -2523px 0px;
}
.flag.flag-ir {
  height: 12px;
  background-position: -2545px 0px;
}
.flag.flag-is {
  height: 15px;
  background-position: -2567px 0px;
}
.flag.flag-it {
  height: 14px;
  background-position: -2589px 0px;
}
.flag.flag-je {
  height: 12px;
  background-position: -2611px 0px;
}
.flag.flag-jm {
  height: 10px;
  background-position: -2633px 0px;
}
.flag.flag-jo {
  height: 10px;
  background-position: -2655px 0px;
}
.flag.flag-jp {
  height: 14px;
  background-position: -2677px 0px;
}
.flag.flag-ke {
  height: 14px;
  background-position: -2699px 0px;
}
.flag.flag-kg {
  height: 12px;
  background-position: -2721px 0px;
}
.flag.flag-kh {
  height: 13px;
  background-position: -2743px 0px;
}
.flag.flag-ki {
  height: 10px;
  background-position: -2765px 0px;
}
.flag.flag-km {
  height: 12px;
  background-position: -2787px 0px;
}
.flag.flag-kn {
  height: 14px;
  background-position: -2809px 0px;
}
.flag.flag-kp {
  height: 10px;
  background-position: -2831px 0px;
}
.flag.flag-kr {
  height: 14px;
  background-position: -2853px 0px;
}
.flag.flag-kw {
  height: 10px;
  background-position: -2875px 0px;
}
.flag.flag-ky {
  height: 10px;
  background-position: -2897px 0px;
}
.flag.flag-kz {
  height: 10px;
  background-position: -2919px 0px;
}
.flag.flag-la {
  height: 14px;
  background-position: -2941px 0px;
}
.flag.flag-lb {
  height: 14px;
  background-position: -2963px 0px;
}
.flag.flag-lc {
  height: 10px;
  background-position: -2985px 0px;
}
.flag.flag-li {
  height: 12px;
  background-position: -3007px 0px;
}
.flag.flag-lk {
  height: 10px;
  background-position: -3029px 0px;
}
.flag.flag-lr {
  height: 11px;
  background-position: -3051px 0px;
}
.flag.flag-ls {
  height: 14px;
  background-position: -3073px 0px;
}
.flag.flag-lt {
  height: 12px;
  background-position: -3095px 0px;
}
.flag.flag-lu {
  height: 12px;
  background-position: -3117px 0px;
}
.flag.flag-lv {
  height: 10px;
  background-position: -3139px 0px;
}
.flag.flag-ly {
  height: 10px;
  background-position: -3161px 0px;
}
.flag.flag-ma {
  height: 14px;
  background-position: -3183px 0px;
}
.flag.flag-mc {
  height: 15px;
  background-position: -3205px 0px;
}
.flag.flag-md {
  height: 10px;
  background-position: -3226px 0px;
}
.flag.flag-me {
  height: 10px;
  background-position: -3248px 0px;
}
.flag.flag-mf {
  height: 14px;
  background-position: -3270px 0px;
}
.flag.flag-mg {
  height: 14px;
  background-position: -3292px 0px;
}
.flag.flag-mh {
  height: 11px;
  background-position: -3314px 0px;
}
.flag.flag-mk {
  height: 10px;
  background-position: -3336px 0px;
}
.flag.flag-ml {
  height: 14px;
  background-position: -3358px 0px;
}
.flag.flag-mm {
  height: 14px;
  background-position: -3380px 0px;
}
.flag.flag-mn {
  height: 10px;
  background-position: -3402px 0px;
}
.flag.flag-mo {
  height: 14px;
  background-position: -3424px 0px;
}
.flag.flag-mp {
  height: 10px;
  background-position: -3446px 0px;
}
.flag.flag-mq {
  height: 14px;
  background-position: -3468px 0px;
}
.flag.flag-mr {
  height: 14px;
  background-position: -3490px 0px;
}
.flag.flag-ms {
  height: 10px;
  background-position: -3512px 0px;
}
.flag.flag-mt {
  height: 14px;
  background-position: -3534px 0px;
}
.flag.flag-mu {
  height: 14px;
  background-position: -3556px 0px;
}
.flag.flag-mv {
  height: 14px;
  background-position: -3578px 0px;
}
.flag.flag-mw {
  height: 14px;
  background-position: -3600px 0px;
}
.flag.flag-mx {
  height: 12px;
  background-position: -3622px 0px;
}
.flag.flag-my {
  height: 10px;
  background-position: -3644px 0px;
}
.flag.flag-mz {
  height: 14px;
  background-position: -3666px 0px;
}
.flag.flag-na {
  height: 14px;
  background-position: -3688px 0px;
}
.flag.flag-nc {
  height: 10px;
  background-position: -3710px 0px;
}
.flag.flag-ne {
  height: 15px;
  background-position: -3732px 0px;
}
.flag.flag-nf {
  height: 10px;
  background-position: -3752px 0px;
}
.flag.flag-ng {
  height: 10px;
  background-position: -3774px 0px;
}
.flag.flag-ni {
  height: 12px;
  background-position: -3796px 0px;
}
.flag.flag-nl {
  height: 14px;
  background-position: -3818px 0px;
}
.flag.flag-no {
  height: 15px;
  background-position: -3840px 0px;
}
.flag.flag-np {
  height: 15px;
  background-position: -3862px 0px;
}
.flag.flag-nr {
  height: 10px;
  background-position: -3877px 0px;
}
.flag.flag-nu {
  height: 10px;
  background-position: -3899px 0px;
}
.flag.flag-nz {
  height: 10px;
  background-position: -3921px 0px;
}
.flag.flag-om {
  height: 10px;
  background-position: -3943px 0px;
}
.flag.flag-pa {
  height: 14px;
  background-position: -3965px 0px;
}
.flag.flag-pe {
  height: 14px;
  background-position: -3987px 0px;
}
.flag.flag-pf {
  height: 14px;
  background-position: -4009px 0px;
}
.flag.flag-pg {
  height: 15px;
  background-position: -4031px 0px;
}
.flag.flag-ph {
  height: 10px;
  background-position: -4053px 0px;
}
.flag.flag-pk {
  height: 14px;
  background-position: -4075px 0px;
}
.flag.flag-pl {
  height: 13px;
  background-position: -4097px 0px;
}
.flag.flag-pm {
  height: 14px;
  background-position: -4119px 0px;
}
.flag.flag-pn {
  height: 10px;
  background-position: -4141px 0px;
}
.flag.flag-pr {
  height: 14px;
  background-position: -4163px 0px;
}
.flag.flag-ps {
  height: 10px;
  background-position: -4185px 0px;
}
.flag.flag-pt {
  height: 14px;
  background-position: -4207px 0px;
}
.flag.flag-pw {
  height: 13px;
  background-position: -4229px 0px;
}
.flag.flag-py {
  height: 11px;
  background-position: -4251px 0px;
}
.flag.flag-qa {
  height: 8px;
  background-position: -4273px 0px;
}
.flag.flag-re {
  height: 14px;
  background-position: -4295px 0px;
}
.flag.flag-ro {
  height: 14px;
  background-position: -4317px 0px;
}
.flag.flag-rs {
  height: 14px;
  background-position: -4339px 0px;
}
.flag.flag-ru {
  height: 14px;
  background-position: -4361px 0px;
}
.flag.flag-rw {
  height: 14px;
  background-position: -4383px 0px;
}
.flag.flag-sa {
  height: 14px;
  background-position: -4405px 0px;
}
.flag.flag-sb {
  height: 10px;
  background-position: -4427px 0px;
}
.flag.flag-sc {
  height: 10px;
  background-position: -4449px 0px;
}
.flag.flag-sd {
  height: 10px;
  background-position: -4471px 0px;
}
.flag.flag-se {
  height: 13px;
  background-position: -4493px 0px;
}
.flag.flag-sg {
  height: 14px;
  background-position: -4515px 0px;
}
.flag.flag-sh {
  height: 10px;
  background-position: -4537px 0px;
}
.flag.flag-si {
  height: 10px;
  background-position: -4559px 0px;
}
.flag.flag-sj {
  height: 15px;
  background-position: -4581px 0px;
}
.flag.flag-sk {
  height: 14px;
  background-position: -4603px 0px;
}
.flag.flag-sl {
  height: 14px;
  background-position: -4625px 0px;
}
.flag.flag-sm {
  height: 15px;
  background-position: -4647px 0px;
}
.flag.flag-sn {
  height: 14px;
  background-position: -4669px 0px;
}
.flag.flag-so {
  height: 14px;
  background-position: -4691px 0px;
}
.flag.flag-sr {
  height: 14px;
  background-position: -4713px 0px;
}
.flag.flag-ss {
  height: 10px;
  background-position: -4735px 0px;
}
.flag.flag-st {
  height: 10px;
  background-position: -4757px 0px;
}
.flag.flag-su {
  height: 10px;
  background-position: -4779px 0px;
}
.flag.flag-sv {
  height: 12px;
  background-position: -4801px 0px;
}
.flag.flag-sx {
  height: 14px;
  background-position: -4823px 0px;
}
.flag.flag-sy {
  height: 14px;
  background-position: -4845px 0px;
}
.flag.flag-sz {
  height: 14px;
  background-position: -4867px 0px;
}
.flag.flag-ta {
  height: 10px;
  background-position: -4889px 0px;
}
.flag.flag-tc {
  height: 10px;
  background-position: -4911px 0px;
}
.flag.flag-td {
  height: 14px;
  background-position: -4933px 0px;
}
.flag.flag-tf {
  height: 14px;
  background-position: -4955px 0px;
}
.flag.flag-tg {
  height: 13px;
  background-position: -4977px 0px;
}
.flag.flag-th {
  height: 14px;
  background-position: -4999px 0px;
}
.flag.flag-tj {
  height: 10px;
  background-position: -5021px 0px;
}
.flag.flag-tk {
  height: 10px;
  background-position: -5043px 0px;
}
.flag.flag-tl {
  height: 10px;
  background-position: -5065px 0px;
}
.flag.flag-tm {
  height: 14px;
  background-position: -5087px 0px;
}
.flag.flag-tn {
  height: 14px;
  background-position: -5109px 0px;
}
.flag.flag-to {
  height: 10px;
  background-position: -5131px 0px;
}
.flag.flag-tr {
  height: 14px;
  background-position: -5153px 0px;
}
.flag.flag-tt {
  height: 12px;
  background-position: -5175px 0px;
}
.flag.flag-tv {
  height: 10px;
  background-position: -5197px 0px;
}
.flag.flag-tw {
  height: 14px;
  background-position: -5219px 0px;
}
.flag.flag-tz {
  height: 14px;
  background-position: -5241px 0px;
}
.flag.flag-ua {
  height: 14px;
  background-position: -5263px 0px;
}
.flag.flag-ug {
  height: 14px;
  background-position: -5285px 0px;
}
.flag.flag-uk {
  height: 10px;
  background-position: -5307px 0px;
}
.flag.flag-um {
  height: 11px;
  background-position: -5329px 0px;
}
.flag.flag-un {
  height: 14px;
  background-position: -5351px 0px;
}
.flag.flag-us {
  height: 11px;
  background-position: -5373px 0px;
}
.flag.flag-uy {
  height: 14px;
  background-position: -5395px 0px;
}
.flag.flag-uz {
  height: 10px;
  background-position: -5417px 0px;
}
.flag.flag-va {
  height: 15px;
  background-position: -5439px 0px;
}
.flag.flag-vc {
  height: 14px;
  background-position: -5456px 0px;
}
.flag.flag-ve {
  height: 14px;
  background-position: -5478px 0px;
}
.flag.flag-vg {
  height: 10px;
  background-position: -5500px 0px;
}
.flag.flag-vi {
  height: 14px;
  background-position: -5522px 0px;
}
.flag.flag-vn {
  height: 14px;
  background-position: -5544px 0px;
}
.flag.flag-vu {
  height: 12px;
  background-position: -5566px 0px;
}
.flag.flag-wf {
  height: 14px;
  background-position: -5588px 0px;
}
.flag.flag-ws {
  height: 10px;
  background-position: -5610px 0px;
}
.flag.flag-xk {
  height: 15px;
  background-position: -5632px 0px;
}
.flag.flag-ye {
  height: 14px;
  background-position: -5654px 0px;
}
.flag.flag-yt {
  height: 14px;
  background-position: -5676px 0px;
}
.flag.flag-za {
  height: 14px;
  background-position: -5698px 0px;
}
.flag.flag-zm {
  height: 14px;
  background-position: -5720px 0px;
}
.flag.flag-zw {
  height: 10px;
  background-position: -5742px 0px;
}

.flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/logos/countries/flags.png");
  background-repeat: no-repeat;
  background-position: 20px 0;
}
@media (min-resolution: 2x) {
  .flag {
    background-image: url("../img/logos/countries/flags@2x.png");
  }
}

.globe {
  background-image: url("../img/logos/countries/globe.png");
  background-size: contain;
  background-position: right;
  box-shadow: none;
  height: 19px;
}
@media (min-resolution: 2x) {
  .globe {
    background-image: url("../img/logos/countries/globe@2x.png");
  }
}